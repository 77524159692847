// import * as React from "react";
// import { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import QrScanner from "react-qr-scanner";
// import Button from "@mui/material/Button";
// import { styled } from "@mui/material/styles";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
// import DialogContentText from "@mui/material/DialogContentText";
// import { Grid, Box, TextField } from "@mui/material";
// import SendIcon from "@mui/icons-material/Send";
// import { SendEWODetails, QRScan } from "../../action/QRScan";
// import QRService from "../../service/QRService";
// import { Card, CardContent, Typography } from "@mui/material";
// import {
//   LaptopMac,
//   Person,
//   Keyboard,
//   Mouse,
//   Monitor,
//   Laptop,
//   Memory,
//   BatteryChargingFull,
//   DesktopWindows,
// } from "@mui/icons-material";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));

// export interface DialogTitleProps {
//   id: string;
//   children?: React.ReactNode;
//   onClose: () => void;
// }

// function BootstrapDialogTitle(props: DialogTitleProps) {
//   const { children, onClose, ...other } = props;

//   return (
//     <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//       {children}
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: "absolute",
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// }

// export default function CustomizedDialogs({ isOpen, isOpenDetailScreen }) {
//   const { responseBody, isButtonVisible } = useSelector((state) => state.qr);
//   const { data, loading } = useSelector((state) => state.userbyServiceNo);
//   const [qrCode, setQRCode] = useState("");
//   const [openDetailsModal, setOpenDetailsModal] = useState(false);
//   const [qrDetails, setQRDetails] = useState(null);
//   const dispatch = useDispatch();
//   useEffect(() => {
//     if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
//       getCameraOptions();
//     }
//   }, []);
//   const handleClose = () => {
//     dispatch({ type: "IS_CLOSE" });
//   };

//   // const handleScan = async (scanData) => {
//   //   if (scanData) {
//   //     setQRCode(scanData.text.trim());
//   //     const ewoNo = parseInt(scanData.text.trim());
//   //     dispatch(QRScan(ewoNo, data[0].ServiceNo));
//   //   }
//   // };

//   const handleScan = async (scanData) => {
//     if (scanData) {
//       const ewoNo = scanData.text.trim();
//       setQRCode(ewoNo);
//       dispatch(QRScan(ewoNo, data[0]?.ServiceNo));

//       if (/^\d+$/.test(ewoNo)) {
//         dispatch({ type: "SHOW_QR_DETAILS_MODAL" });
//       } else if (/[A-Za-z]/.test(ewoNo)) {
//         const response = await QRService.GetEWODetails(ewoNo);
//         if (response.data.StatusCode === 200) {
//           setQRDetails(response.data.ResultSet);
//           setOpenDetailsModal(true);
//         }
//       }
//     }
//   };

//   // const handleScan = async (scanData) => {
//   //   if (scanData) {
//   //     const ewoNo = scanData.text.trim();
//   //     setQRCode(ewoNo);
//   //     dispatch(QRScan(ewoNo, data[0]?.ServiceNo));

//   //     if (/^\d+$/.test(ewoNo)) {
//   //       dispatch({ type: "SHOW_QR_DETAILS_MODAL" });
//   //     } else if (/[A-Za-z]/.test(ewoNo)) {
//   //       setOpenDetailsModal(true);
//   //     }
//   //   }
//   // };

//   const handleViewDetails = () => {
//     setOpenDetailsModal(true);
//   };
//   const handleCloseDetailsModal = () => {
//     setOpenDetailsModal(false);
//   };
//   const handleError = (error) => {
//     console.error(error);
//   };
//   const getCameraOptions = async () => {
//     const devices = await navigator.mediaDevices.enumerateDevices();
//     const videoDevices = devices.filter(
//       (device) => device.kind === "videoinput"
//     );
//     const rearCamera = videoDevices.find(
//       (device) => device.label.includes("back") || device.label.includes("rear")
//     );
//   };
//   const previewStyle = {
//     width: "100%",
//     height: "auto",
//   };
//   return (
//     <div>
//       <div>
//         <BootstrapDialog
//           onClose={handleClose}
//           aria-labelledby="customized-dialog-title"
//           open={isOpen}
//         >
//           <BootstrapDialogTitle
//             id="customized-dialog-title"
//             onClose={handleClose}
//           >
//             Scan Your QR Code
//           </BootstrapDialogTitle>
//           <DialogContent dividers>
//             <QrScanner
//               delay={1000}
//               onError={handleError}
//               onScan={handleScan}
//               style={previewStyle}
//               constraints={{
//                 audio: false,
//                 video: { facingMode: "environment", autoFocus: true },
//               }}
//             />
//           </DialogContent>
//           <DialogActions>
//             <Button variant="outlined" onClick={handleViewDetails}>
//               View
//             </Button>
//             <Button
//               autoFocus
//               onClick={() => {
//                 handleClose();
//               }}
//             >
//               Scan Again
//             </Button>
//           </DialogActions>
//         </BootstrapDialog>
//       </div>
//       <div>
//         {/* QR Code EWO Details Modal */}
//         <BootstrapDialog
//           onClose={handleClose}
//           aria-labelledby="customized-dialog-title"
//           open={isOpenDetailScreen}
//         >
//           <BootstrapDialogTitle
//             id="customized-dialog-title"
//             onClose={handleClose}
//           >
//             EWO Details
//           </BootstrapDialogTitle>
//           <DialogContent dividers>
//             <DialogContentText
//               id="scroll-dialog-description"
//               tabIndex={-1}
//               marginLeft={-2}
//             >
//               <Grid
//                 container
//                 spacing={0}
//                 direction="column"
//                 alignItems="center"
//                 justifyContent="center"
//                 padding={1}
//               >
//                 <Box
//                   sx={{
//                     "& .MuiTextField-root": { m: 1, width: "100%" },
//                     alignItems: "center",
//                     justifyContent: "center",
//                   }}
//                   justifyContent={"center"}
//                   alignItems={"center"}
//                 >
//                   <div style={{ textAlign: "center" }}>
//                     <Box display="flex" alignItems="center" gap={1}>
//                       <Typography variant="subtitle1" fontWeight="bold">
//                         EWO No:
//                       </Typography>

//                       <Typography variant="body2">
//                         {responseBody !== null ? responseBody.EwoNo : "N/A"}
//                       </Typography>
//                     </Box>
//                     <Box display="flex" alignItems="center" gap={1}>
//                       <Typography variant="subtitle1" fontWeight="bold">
//                         Authorize Person:
//                       </Typography>

//                       <Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
//                         {responseBody !== null
//                           ? responseBody.AuthorizePerson
//                           : "N/A"}
//                       </Typography>
//                     </Box>
//                     <Box display="flex" alignItems="center" gap={1}>
//                       <Typography variant="subtitle1" fontWeight="bold">
//                         Estimate Amount:
//                       </Typography>

//                       <Typography variant="body2">
//                         {responseBody !== null
//                           ? responseBody.EstimatedAmount
//                           : "N/A"}
//                       </Typography>
//                     </Box>
//                     <Box display="flex" alignItems="center" gap={1}>
//                       <Typography variant="subtitle1" fontWeight="bold">
//                         Billed Amount:
//                       </Typography>
//                     </Box>
//                     <Box display="flex" alignItems="center" gap={1}>
//                       <Typography variant="subtitle1" fontWeight="bold">
//                         Workdone Receive Amount:
//                       </Typography>

//                       <Typography variant="body2">
//                         {responseBody !== null
//                           ? responseBody.authorize_person
//                           : "N/A"}
//                       </Typography>
//                     </Box>
//                     <Box display="flex" alignItems="center" gap={1}>
//                       <Typography variant="subtitle1" fontWeight="bold">
//                         Certified By:
//                       </Typography>

//                       <Typography variant="body2">
//                         {responseBody !== null
//                           ? responseBody.ApprovedBy
//                           : "N/A"}
//                       </Typography>
//                     </Box>
//                     <Box display="flex" alignItems="center" gap={1}>
//                       <Typography variant="subtitle1" fontWeight="bold">
//                         Evaluated By:
//                       </Typography>

//                       <Typography variant="body2">
//                         {responseBody !== null
//                           ? responseBody.EvaluationBy
//                           : "N/A"}
//                       </Typography>
//                     </Box>
//                     <TextField
//                       id="outlined-read-only-input"
//                       inputProps={{
//                         style: {
//                           color:
//                             responseBody !== null
//                               ? responseBody.StatusTxtcolor
//                               : "",
//                         },
//                       }}
//                       defaultValue={
//                         responseBody !== null ? responseBody.EwoStatus : ""
//                       }
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                       style={{
//                         backgroundColor:
//                           responseBody !== null
//                             ? responseBody.StatusBckcolor
//                             : "",
//                         borderRadius: 5,
//                       }}
//                     />
//                     <Box mt={2} mb={2}>
//                       <hr style={{ border: "5px solid #ccc" }} />
//                     </Box>
//                     <TextField
//                       id="outlined-read-only-input"
//                       label="Recieved By:"
//                       defaultValue={
//                         responseBody !== null ? responseBody.RecievedBy : ""
//                       }
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                     />
//                     <TextField
//                       id="outlined-read-only-input"
//                       label="Issued By:"
//                       defaultValue={
//                         responseBody !== null ? responseBody.IssuedBy : ""
//                       }
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                     />
//                     <TextField
//                       id="outlined-read-only-input"
//                       label="Issued Date"
//                       defaultValue={
//                         responseBody !== null ? responseBody.IssuedDate : ""
//                       }
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                     />
//                     <TextField
//                       id="outlined-read-only-input"
//                       label="Serial No:"
//                       defaultValue={
//                         responseBody !== null ? responseBody.SerialNo : ""
//                       }
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                     />
//                     <TextField
//                       id="outlined-read-only-input"
//                       label="DocType"
//                       defaultValue={
//                         responseBody !== null ? responseBody.DocType : ""
//                       }
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                     />
//                     <TextField
//                       id="outlined-read-only-input"
//                       label="Remarks"
//                       defaultValue={
//                         responseBody !== null ? responseBody.Remarks : ""
//                       }
//                       InputProps={{
//                         readOnly: true,
//                       }}
//                     />
//                   </div>
//                 </Box>
//               </Grid>
//             </DialogContentText>
//           </DialogContent>
//           <DialogActions>
//             {isButtonVisible && (
//               <Button
//                 variant="contained"
//                 endIcon={<SendIcon />}
//                 autoFocus
//                 onClick={() => {
//                   handleClose();
//                   dispatch(SendEWODetails(responseBody));
//                 }}
//               >
//                 Send Doc
//               </Button>
//             )}
//             <Button
//               variant="contained"
//               endIcon={<CloseIcon />}
//               color="error"
//               autoFocus
//               onClick={() => {
//                 handleClose();
//               }}
//             >
//               Close
//             </Button>
//           </DialogActions>
//         </BootstrapDialog>

//         {/* QR Code Details Modal */}
//         <BootstrapDialog
//           onClose={handleCloseDetailsModal}
//           aria-labelledby="qr-code-details-title"
//           open={openDetailsModal}
//         >
//           <BootstrapDialogTitle
//             id="qr-code-details-title"
//             onClose={handleCloseDetailsModal}
//           >
//             CDPLC QR
//           </BootstrapDialogTitle>
//           <DialogContent dividers>
//             <Grid container spacing={2}>
//               <Grid item xs={12}>
//                 <Card
//                   sx={{
//                     border: "1px solid #b1a9a9",
//                     borderRadius: 5,
//                     padding: 2,
//                     marginBottom: 2,
//                   }}
//                 >
//                   <CardContent>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <Typography
//                         variant="h6"
//                         fontWeight="bold"
//                         fontSize={18}
//                         mb={2}
//                       >
//                         QR Code Details
//                       </Typography>

//                       {/* Device Icon Selection */}
//                       {qrCode?.includes("S") && (
//                         <IconButton sx={{ color: "black", fontSize: 30 }}>
//                           <Monitor sx={{ fontSize: 50, color: "black" }} />
//                         </IconButton>
//                       )}
//                       {qrCode?.includes("K") && (
//                         <IconButton sx={{ color: "black", fontSize: 30 }}>
//                           <Keyboard sx={{ fontSize: 50, color: "black" }} />
//                         </IconButton>
//                       )}
//                       {qrCode?.includes("M") && (
//                         <IconButton sx={{ color: "black", fontSize: 30 }}>
//                           <Mouse sx={{ fontSize: 50, color: "black" }} />
//                         </IconButton>
//                       )}
//                       {qrCode?.includes("L") && (
//                         <IconButton sx={{ color: "black", fontSize: 30 }}>
//                           <LaptopMac sx={{ fontSize: 50, color: "black" }} />
//                         </IconButton>
//                       )}
//                       {qrCode?.includes("U") && (
//                         <IconButton sx={{ color: "black", fontSize: 30 }}>
//                           <BatteryChargingFull
//                             sx={{ fontSize: 50, color: "black" }}
//                           />
//                         </IconButton>
//                       )}
//                       {qrCode?.includes("P") && (
//                         <IconButton sx={{ color: "black", fontSize: 30 }}>
//                           <DesktopWindows
//                             sx={{ fontSize: 50, color: "black" }}
//                           />
//                         </IconButton>
//                       )}
//                       {/* {qrCode?.includes("O") && null} */}
//                       {qrCode?.includes("O") && (
//                         <IconButton sx={{ color: "black", fontSize: 30 }}>
//                           <Memory sx={{ fontSize: 50, color: "black" }} />
//                         </IconButton>
//                       )}
//                       {/* {qrCode?.includes("C") && null} */}
//                       {qrCode?.includes("C") && (
//                         <IconButton sx={{ color: "black", fontSize: 30 }}>
//                           <DesktopWindows
//                             sx={{ fontSize: 50, color: "black" }}
//                           />
//                         </IconButton>
//                       )}
//                     </Box>
//                     {/* QR Code: */}
//                     <Grid container spacing={2}>
//                       <Grid item xs={6}>
//                         <Typography variant="body2" fontWeight="bold">
//                           QR Code:
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="body2">
//                           {qrCode || "Not Available"}
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="body2" fontWeight="bold">
//                           Device:
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="body2">
//                           {qrCode?.includes("S")
//                             ? "Screen"
//                             : qrCode?.includes("K")
//                             ? "Keyboard"
//                             : qrCode?.includes("M")
//                             ? "Mouse"
//                             : qrCode?.includes("L")
//                             ? "Laptop"
//                             : qrCode?.includes("U")
//                             ? "UPS"
//                             : qrCode?.includes("P")
//                             ? "Computer"
//                             : qrCode?.includes("O")
//                             ? "Other"
//                             : qrCode?.includes("C")
//                             ? "Machine"
//                             : "N/A"}
//                         </Typography>
//                       </Grid>
//                       {/* Other fields */}
//                       <Grid item xs={6}>
//                         <Typography variant="body2" fontWeight="bold">
//                           Make:
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="body2">
//                           {qrDetails?.ICT_ScreenModels[0]?.Mon_Make || "N/A"}
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="body2" fontWeight="bold">
//                           Model:
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="body2">
//                           {qrDetails?.ICT_ScreenModels[0]?.Mon_Model || "N/A"}
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="body2" fontWeight="bold">
//                           Status:
//                         </Typography>
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="body2">
//                           {qrDetails?.ICT_ScreenModels[0]?.Mon_Status === "A"
//                             ? "Active"
//                             : qrDetails?.ICT_ScreenModels[0]?.Mon_Status === "I"
//                             ? "Inactive"
//                             : qrDetails?.ICT_ScreenModels[0]?.Mon_Status === "D"
//                             ? "Disposable"
//                             : "N/A"}
//                         </Typography>
//                       </Grid>
//                     </Grid>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             </Grid>

//             {/* Associated User Section */}

//             <Grid item xs={12}>
//               <Card
//                 sx={{
//                   border: "1px solid #b1a9a9",
//                   borderRadius: 5,
//                   padding: 2,
//                   marginBottom: 2,
//                 }}
//               >
//                 <CardContent>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <Typography
//                       variant="h6"
//                       fontWeight="bold"
//                       fontSize={18}
//                       mb={2}
//                     >
//                       Associated User Details
//                     </Typography>
//                     <IconButton sx={{ color: "black", fontSize: 30 }}>
//                       <Person sx={{ fontSize: 50 }} />
//                     </IconButton>
//                   </Box>

//                   <Grid container spacing={2}>
//                     <Grid item xs={6}>
//                       <Typography variant="body2" fontWeight="bold">
//                         Service No:
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography variant="body2">
//                         {qrDetails?.Service_No || "N/A"}
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography variant="body2" fontWeight="bold">
//                         Name:
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography variant="body2">
//                         {qrDetails?.Emp_Name || "N/A"}
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography variant="body2" fontWeight="bold">
//                         Computer Name:
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography variant="body2">
//                         {qrDetails?.Com_Name || "N/A"}
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography variant="body2" fontWeight="bold">
//                         Email Address:
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography variant="body2">
//                         {qrDetails?.Emp_Email || "N/A"}
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography variant="body2" fontWeight="bold">
//                         Extension:
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography variant="body2">
//                         {qrDetails?.Emp_Exte || "N/A"}
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography variant="body2" fontWeight="bold">
//                         Physical Location:
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography variant="body2">
//                         {qrDetails?.Com_Loc || "N/A"}
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography variant="body2" fontWeight="bold">
//                         Status:
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Typography variant="body2">
//                         {qrDetails?.Status === "A"
//                           ? "Active"
//                           : qrDetails?.Status === "I"
//                           ? "Inactive"
//                           : qrDetails?.Status === "D"
//                           ? "Disposable"
//                           : "N/A"}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 </CardContent>
//               </Card>
//             </Grid>

//             {/* Associated Devices Section */}
//             <Grid item xs={12}>
//               <Card
//                 // sx={{ border: "1px solid #000", borderRadius: 0, padding: 2 }}
//                 sx={{
//                   border: "1px solid #b1a9a9",
//                   borderRadius: 5,
//                   padding: 2,
//                   marginBottom: 2,
//                 }}
//               >
//                 <CardContent>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <Typography
//                       variant="h6"
//                       fontWeight="bold"
//                       fontSize={18}
//                       mb={2}
//                     >
//                       Other Associated Devices
//                     </Typography>
//                   </Box>

//                   {[
//                     { title: "Laptop", prefix: "Lap", icon: <Laptop /> },
//                     { title: "Machine", prefix: "Mac", icon: <Memory /> },
//                     { title: "Keyboard", prefix: "Key", icon: <Keyboard /> },
//                     { title: "Mouse", prefix: "Mou", icon: <Mouse /> },
//                   ].map((device, index) => (
//                     <Box
//                       key={index}
//                       sx={{
//                         border: "1px solid #b1a9a9",
//                         padding: 2,
//                         marginBottom: 2,
//                         borderRadius: 3,
//                       }}
//                     >
//                       <Box
//                         sx={{
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "space-between",
//                         }}
//                       >
//                         <Typography
//                           variant="h6"
//                           fontWeight="bold"
//                           fontSize={16}
//                           mb={1}
//                         >
//                           {device.title}
//                         </Typography>
//                         {device.icon}
//                       </Box>
//                       <Grid container spacing={2}>
//                         <Grid item xs={6}>
//                           <Typography variant="body2" fontWeight="bold">
//                             {device.title} Code:
//                           </Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                           <Typography variant="body2">
//                             {qrDetails?.[`${device.prefix}_Code`] || "N/A"}
//                           </Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                           <Typography variant="body2" fontWeight="bold">
//                             {device.title} Make:
//                           </Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                           <Typography variant="body2">
//                             {qrDetails?.[`${device.prefix}_Make`] || "N/A"}
//                           </Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                           <Typography variant="body2" fontWeight="bold">
//                             {device.title} Model:
//                           </Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                           <Typography variant="body2">
//                             {qrDetails?.[`${device.prefix}_Model`] || "N/A"}
//                           </Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                           <Typography variant="body2" fontWeight="bold">
//                             {device.title} Status:
//                           </Typography>
//                         </Grid>
//                         <Grid item xs={6}>
//                           <Typography variant="body2">
//                             {qrDetails?.[`${device.prefix}_Status`] === "A"
//                               ? "Active"
//                               : qrDetails?.[`${device.prefix}_Status`] === "I"
//                               ? "Inactive"
//                               : qrDetails?.[`${device.prefix}_Status`] === "D"
//                               ? "Disposable"
//                               : "N/A"}
//                           </Typography>
//                         </Grid>
//                       </Grid>
//                     </Box>
//                   ))}
//                 </CardContent>
//               </Card>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button variant="contained" onClick={handleCloseDetailsModal}>
//               Close
//             </Button>
//           </DialogActions>
//         </BootstrapDialog>
//       </div>
//     </div>
//   );
// }

import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import QrScanner from "react-qr-scanner";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import { Grid, Box, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { SendEWODetails, QRScan } from "../../action/QRScan";
import QRService from "../../service/QRService";
import { Card, CardContent, Typography } from "@mui/material";
import {
  LaptopMac,
  Person,
  Keyboard,
  Mouse,
  Monitor,
  Laptop,
  Memory,
  BatteryChargingFull,
  DesktopWindows,
} from "@mui/icons-material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function CustomizedDialogs({ isOpen, isOpenDetailScreen }) {
  const { responseBody, isButtonVisible } = useSelector((state) => state.qr);
  const { data, loading } = useSelector((state) => state.userbyServiceNo);
  const [qrCode, setQRCode] = useState("");
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [qrDetails, setQRDetails] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      getCameraOptions();
    }
  }, []);
  const handleClose = () => {
    dispatch({ type: "IS_CLOSE" });
  };

  // const handleScan = async (scanData) => {
  //   if (scanData) {
  //     setQRCode(scanData.text.trim());
  //     const ewoNo = parseInt(scanData.text.trim());
  //     dispatch(QRScan(ewoNo, data[0].ServiceNo));
  //   }
  // };

  const handleScan = async (scanData) => {
    if (scanData) {
      const ewoNo = scanData.text.trim();
      setQRCode(ewoNo);
      dispatch(QRScan(ewoNo, data[0]?.ServiceNo));

      if (/^\d+$/.test(ewoNo)) {
        dispatch({ type: "SHOW_QR_DETAILS_MODAL" });
      } else if (/[A-Za-z]/.test(ewoNo)) {
        const response = await QRService.GetEWODetails(ewoNo);
        if (response.data.StatusCode === 200) {
          setQRDetails(response.data.ResultSet);
          setOpenDetailsModal(true);
        }
      }
    }
  };

  // const handleScan = async (scanData) => {
  //   if (scanData) {
  //     const ewoNo = scanData.text.trim();
  //     setQRCode(ewoNo);
  //     dispatch(QRScan(ewoNo, data[0]?.ServiceNo));

  //     if (/^\d+$/.test(ewoNo)) {
  //       dispatch({ type: "SHOW_QR_DETAILS_MODAL" });
  //     } else if (/[A-Za-z]/.test(ewoNo)) {
  //       setOpenDetailsModal(true);
  //     }
  //   }
  // };

  const handleViewDetails = () => {
    setOpenDetailsModal(true);
  };
  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
  };
  const handleError = (error) => {
    console.error(error);
  };
  const getCameraOptions = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );
    const rearCamera = videoDevices.find(
      (device) => device.label.includes("back") || device.label.includes("rear")
    );
  };
  const previewStyle = {
    width: "100%",
    height: "auto",
  };
  return (
    <div>
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={isOpen}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Scan Your QR Code
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <QrScanner
              delay={1000}
              onError={handleError}
              onScan={handleScan}
              style={previewStyle}
              constraints={{
                audio: false,
                video: { facingMode: "environment", autoFocus: true },
              }}
            />
          </DialogContent>
          <DialogActions>
            {/* <Button variant="outlined" onClick={handleViewDetails}>
              View
            </Button> */}
            <Button
              autoFocus
              onClick={() => {
                handleClose();
              }}
            >
              Scan Again
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
      <div>
        {/* QR Code EWO Details Modal */}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={isOpenDetailScreen}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            EWO Details
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
              marginLeft={-2}
            >
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                padding={1}
              >
                <Box
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "100%" },
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <div style={{ textAlign: "center" }}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        EWO No:
                      </Typography>

                      <Typography variant="body2">
                        {responseBody !== null ? responseBody.EwoNo : "N/A"}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Authorize Person:
                      </Typography>

                      <Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
                        {responseBody !== null
                          ? responseBody.AuthorizePerson
                          : "N/A"}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Estimate Amount:
                      </Typography>

                      <Typography variant="body2">
                        {responseBody !== null
                          ? responseBody.EstimatedAmount
                          : "N/A"}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Billed Amount:
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Workdone Receive Amount:
                      </Typography>

                      <Typography variant="body2">
                        {responseBody !== null
                          ? responseBody.authorize_person
                          : "N/A"}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Certified By:
                      </Typography>

                      <Typography variant="body2">
                        {responseBody !== null
                          ? responseBody.ApprovedBy
                          : "N/A"}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Evaluated By:
                      </Typography>

                      <Typography variant="body2">
                        {responseBody !== null
                          ? responseBody.EvaluationBy
                          : "N/A"}
                      </Typography>
                    </Box>
                    <TextField
                      id="outlined-read-only-input"
                      inputProps={{
                        style: {
                          color:
                            responseBody !== null
                              ? responseBody.StatusTxtcolor
                              : "",
                        },
                      }}
                      defaultValue={
                        responseBody !== null ? responseBody.EwoStatus : ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      style={{
                        backgroundColor:
                          responseBody !== null
                            ? responseBody.StatusBckcolor
                            : "",
                        borderRadius: 5,
                      }}
                    />
                    <Box mt={2} mb={2}>
                      <hr style={{ border: "5px solid #ccc" }} />
                    </Box>
                    <TextField
                      id="outlined-read-only-input"
                      label="Recieved By:"
                      defaultValue={
                        responseBody !== null ? responseBody.RecievedBy : ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="outlined-read-only-input"
                      label="Issued By:"
                      defaultValue={
                        responseBody !== null ? responseBody.IssuedBy : ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="outlined-read-only-input"
                      label="Issued Date"
                      defaultValue={
                        responseBody !== null ? responseBody.IssuedDate : ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="outlined-read-only-input"
                      label="Serial No:"
                      defaultValue={
                        responseBody !== null ? responseBody.SerialNo : ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="outlined-read-only-input"
                      label="DocType"
                      defaultValue={
                        responseBody !== null ? responseBody.DocType : ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      id="outlined-read-only-input"
                      label="Remarks"
                      defaultValue={
                        responseBody !== null ? responseBody.Remarks : ""
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </Box>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {isButtonVisible && (
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                autoFocus
                onClick={() => {
                  handleClose();
                  dispatch(SendEWODetails(responseBody));
                }}
              >
                Send Doc
              </Button>
            )}
            <Button
              variant="contained"
              endIcon={<CloseIcon />}
              color="error"
              autoFocus
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>

        {/* QR Code Details Modal */}
        <BootstrapDialog
          onClose={handleCloseDetailsModal}
          aria-labelledby="qr-code-details-title"
          open={openDetailsModal}
        >
          <BootstrapDialogTitle
            id="qr-code-details-title"
            onClose={handleCloseDetailsModal}
          >
            CDPLC QR
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    border: "1px solid #b1a9a9",
                    borderRadius: 5,
                    padding: 2,
                    marginBottom: 2,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        fontSize={18}
                        mb={2}
                      >
                        QR Code Details
                      </Typography>

                      {/* Device Icon Selection */}
                      {qrCode?.includes("S") && (
                        <IconButton sx={{ color: "black", fontSize: 30 }}>
                          <Monitor sx={{ fontSize: 50, color: "black" }} />
                        </IconButton>
                      )}
                      {qrCode?.includes("K") && (
                        <IconButton sx={{ color: "black", fontSize: 30 }}>
                          <Keyboard sx={{ fontSize: 50, color: "black" }} />
                        </IconButton>
                      )}
                      {qrCode?.includes("M") && (
                        <IconButton sx={{ color: "black", fontSize: 30 }}>
                          <Mouse sx={{ fontSize: 50, color: "black" }} />
                        </IconButton>
                      )}
                      {qrCode?.includes("L") && (
                        <IconButton sx={{ color: "black", fontSize: 30 }}>
                          <LaptopMac sx={{ fontSize: 50, color: "black" }} />
                        </IconButton>
                      )}
                      {qrCode?.includes("U") && (
                        <IconButton sx={{ color: "black", fontSize: 30 }}>
                          <BatteryChargingFull
                            sx={{ fontSize: 50, color: "black" }}
                          />
                        </IconButton>
                      )}
                      {qrCode?.includes("P") && (
                        <IconButton sx={{ color: "black", fontSize: 30 }}>
                          <DesktopWindows
                            sx={{ fontSize: 50, color: "black" }}
                          />
                        </IconButton>
                      )}
                      {/* {qrCode?.includes("O") && null} */}
                      {qrCode?.includes("O") && (
                        <IconButton sx={{ color: "black", fontSize: 30 }}>
                          <Memory sx={{ fontSize: 50, color: "black" }} />
                        </IconButton>
                      )}
                      {/* {qrCode?.includes("C") && null} */}
                      {qrCode?.includes("C") && (
                        <IconButton sx={{ color: "black", fontSize: 30 }}>
                          <DesktopWindows
                            sx={{ fontSize: 50, color: "black" }}
                          />
                        </IconButton>
                      )}
                    </Box>
                    {/* QR Code: */}
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body2" fontWeight="bold">
                          QR Code:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          {qrCode || "Not Available"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" fontWeight="bold">
                          Device:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          {qrCode?.includes("S")
                            ? "Screen"
                            : qrCode?.includes("K")
                            ? "Keyboard"
                            : qrCode?.includes("M")
                            ? "Mouse"
                            : qrCode?.includes("L")
                            ? "Laptop"
                            : qrCode?.includes("U")
                            ? "UPS"
                            : qrCode?.includes("P")
                            ? "Computer"
                            : qrCode?.includes("O")
                            ? "Other"
                            : qrCode?.includes("C")
                            ? "Machine"
                            : "N/A"}
                        </Typography>
                      </Grid>
                      {/* Other fields */}
                      <Grid item xs={6}>
                        <Typography variant="body2" fontWeight="bold">
                          Make:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          {qrDetails?.ICT_ScreenModels[0]?.Mon_Make || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" fontWeight="bold">
                          Model:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          {qrDetails?.ICT_ScreenModels[0]?.Mon_Model || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" fontWeight="bold">
                          Status:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          {qrDetails?.ICT_ScreenModels[0]?.Mon_Status === "A"
                            ? "Active"
                            : qrDetails?.ICT_ScreenModels[0]?.Mon_Status === "I"
                            ? "Inactive"
                            : qrDetails?.ICT_ScreenModels[0]?.Mon_Status === "D"
                            ? "Disposable"
                            : "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            {/* Associated User Section */}

            <Grid item xs={12}>
              <Card
                sx={{
                  border: "1px solid #b1a9a9",
                  borderRadius: 5,
                  padding: 2,
                  marginBottom: 2,
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      fontSize={18}
                      mb={2}
                    >
                      Associated User Details
                    </Typography>
                    <IconButton sx={{ color: "black", fontSize: 30 }}>
                      <Person sx={{ fontSize: 50 }} />
                    </IconButton>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" fontWeight="bold">
                        Service No:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        {qrDetails?.Service_No || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" fontWeight="bold">
                        Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        {qrDetails?.Emp_Name || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" fontWeight="bold">
                        Computer Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        {qrDetails?.Com_Name || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" fontWeight="bold">
                        Email Address:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        {qrDetails?.Emp_Email || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" fontWeight="bold">
                        Extension:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        {qrDetails?.Emp_Exte || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" fontWeight="bold">
                        Physical Location:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        {qrDetails?.Com_Loc || "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" fontWeight="bold">
                        Status:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        {qrDetails?.Status === "A"
                          ? "Active"
                          : qrDetails?.Status === "I"
                          ? "Inactive"
                          : qrDetails?.Status === "D"
                          ? "Disposable"
                          : "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Associated Devices Section */}
            <Grid item xs={12}>
              <Card
                // sx={{ border: "1px solid #000", borderRadius: 0, padding: 2 }}
                sx={{
                  border: "1px solid #b1a9a9",
                  borderRadius: 5,
                  padding: 2,
                  marginBottom: 2,
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      fontSize={18}
                      mb={2}
                    >
                      Other Associated Devices
                    </Typography>
                  </Box>

                  {[
                    { title: "Laptop", prefix: "Lap", icon: <Laptop /> },
                    { title: "Machine", prefix: "Mac", icon: <Memory /> },
                    { title: "Keyboard", prefix: "Key", icon: <Keyboard /> },
                    { title: "Mouse", prefix: "Mou", icon: <Mouse /> },
                  ].map((device, index) => {
                    // Check if the code is "N/A"
                    const deviceCode =
                      qrDetails?.[`${device.prefix}_Code`] || "N/A";
                    if (deviceCode === "N/A") {
                      return null; // Skip rendering this device block if code is "N/A"
                    }

                    return (
                      <Box
                        key={index}
                        sx={{
                          border: "1px solid #b1a9a9",
                          padding: 2,
                          marginBottom: 2,
                          borderRadius: 3,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            fontSize={16}
                            mb={1}
                          >
                            {device.title}
                          </Typography>
                          {device.icon}
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="body2" fontWeight="bold">
                              {device.title} Code:
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">
                              {deviceCode}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" fontWeight="bold">
                              {device.title} Make:
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">
                              {qrDetails?.[`${device.prefix}_Make`] || "N/A"}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" fontWeight="bold">
                              {device.title} Model:
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">
                              {qrDetails?.[`${device.prefix}_Model`] || "N/A"}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" fontWeight="bold">
                              {device.title} Status:
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">
                              {qrDetails?.[`${device.prefix}_Status`] === "A"
                                ? "Active"
                                : qrDetails?.[`${device.prefix}_Status`] === "I"
                                ? "Inactive"
                                : qrDetails?.[`${device.prefix}_Status`] === "D"
                                ? "Disposable"
                                : "N/A"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </CardContent>
              </Card>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleCloseDetailsModal}>
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </div>
  );
}
